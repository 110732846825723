//import * as diff from './diff';
import { Y, ydoc, wsProvider } from "../multi-user";
import _ from 'lodash';
import { FRONTEND_DATA, YTransactionTypes } from "../../globals";
import { Observable } from 'lib0/observable';
import { history, store } from "../../index";
import { actions } from "../../actions";
import * as Sentry from "@sentry/browser";

import * as array from 'lib0/array';
import * as math from 'lib0/math';
import * as map from 'lib0/map';
import * as set from 'lib0/set';

const yjsSnapshotTimeout = 500;
let pauseCallCount = 0;
let stackItemBeingPopped;

const getStateVector = store => {
	const sm = new Map();
	store.clients.forEach((structs, client) => {
		const struct = structs[structs.length - 1];
		sm.set(client, struct.id.clock + struct.length);
	});
	return sm
};

const sortAndMergeDeleteSet = ds => {
	ds.clients.forEach(dels => {
		dels.sort((a, b) => a.clock - b.clock)
		// merge items without filtering or splicing the array
		// i is the current pointer
		// j refers to the current insert position for the pointed item
		// try to merge dels[i] into dels[j-1] or set dels[j]=dels[i]
		let i, j
		for (i = 1, j = 1; i < dels.length; i++) {
			const left = dels[j - 1]
			const right = dels[i]
			if (left.clock + left.len >= right.clock) {
				left.len = math.max(left.len, right.clock + right.len - left.clock)
			} else {
				if (j < i) {
					dels[j] = right
				}
				j++
			}
		}
		dels.length = j
	})
}

const mergeDeleteSets = dss => {
	const merged = Y.createDeleteSet();
	for (let dssI = 0; dssI < dss.length; dssI++) {
		dss[dssI].clients.forEach((delsLeft, client) => {
			if (!merged.clients.has(client)) {
				// Write all missing keys from current ds and all following.
				// If merged already contains `client` current ds has already been added.
				/**
				 * @type {Array<DeleteItem>}
				 */
				const dels = delsLeft.slice()
				for (let i = dssI + 1; i < dss.length; i++) {
					array.appendTo(dels, dss[i].clients.get(client) || [])
				}
				merged.clients.set(client, dels)
			}
		})
	}
	sortAndMergeDeleteSet(merged)
	return merged
}

let nestedTransactions;
let runningNestedTransaction;

const startNestedTransaction = (nativeTransaction, doc, origin) => {

	if(runningNestedTransaction) {
		// always end a running nested transaction before starting a new one
		endNestedTransaction(nativeTransaction);
	}

	// store original beforeState, deleteSet and changed map
	nativeTransaction._originalBeforeState = nativeTransaction.beforeState;
	nativeTransaction._originalDeleteSet = nativeTransaction.deleteSet;
	nativeTransaction._originalChanged = nativeTransaction.changed;

	// First set the correct beforeState. Then create new, empty delete and change fields. 
	// These will be populated by YJS while the transaction runs.
	nativeTransaction.beforeState = getStateVector(doc.store);
	nativeTransaction.deleteSet = Y.createDeleteSet();
	nativeTransaction.changed = new Map();

	// create the fake nested transaction
	runningNestedTransaction = new Y.Transaction(doc, origin, true);
	
	// store the nested transaction
	nestedTransactions.push(runningNestedTransaction);

}

const endNestedTransaction = (nativeTransaction) => {

	if(!runningNestedTransaction) {
		return;
	}

	// steal the deletes and changes from the native YJS transaction
	runningNestedTransaction.deleteSet = nativeTransaction.deleteSet;
	runningNestedTransaction.changed = nativeTransaction.changed;

	// calculate state vector at this point in time
	runningNestedTransaction.afterState = getStateVector(runningNestedTransaction.doc.store);

	// restore the original beforeState, deleteSet and changed map on the native YJS transaction
	nativeTransaction.beforeState = nativeTransaction._originalBeforeState;
	nativeTransaction.deleteSet = nativeTransaction._originalDeleteSet;
	nativeTransaction.changed = nativeTransaction._originalChanged;

	delete nativeTransaction._originalDeleteSet;
	delete nativeTransaction._originalChanged;
	delete nativeTransaction._originalBeforeState;

	runningNestedTransaction = null;

}

// patch YJS to handle nested transactions
Y.Doc.prototype._originalTransact = Y.Doc.prototype.transact;

Y.Doc.prototype.transact = function(f, origin) {

	let initialCall = !runningNestedTransaction;

	if(initialCall) {
		nestedTransactions = [];
	}

	return this._originalTransact((nativeTransaction) => {

		const parentOrigin = runningNestedTransaction?.origin;

		/* start leading transaction to capture the following segment
			ydoc.transact(() => {
				[ capturing his segment ]
				ydoc.transact(() => {
				});
			})
		*/

		startNestedTransaction(nativeTransaction, this, origin);

		// run transaction body
		f(nativeTransaction);

		if(!initialCall) {

			/* start trailing transaction to capture the following segment
				ydoc.transact(() => {
					ydoc.transact(() => {
					});
					[ capturing this segment ]
				})
			*/
			startNestedTransaction(nativeTransaction, this, parentOrigin);

		} else {
			
			// we've reached the end of the initial, top-level transaction. Finalize
			// any nested transactions and merge all nested transactions back into 
			// the native YJS transaction
			endNestedTransaction(nativeTransaction);

			// we cannot override internal `transact` calls, so we earmark this transaction
			// so we can ignore this in the native undoManager's `afterTransaction` callback
			nativeTransaction._preventNativeUndoHandler = true;

			// finalize nested & native transaction
			nestedTransactions.forEach(nestedTransaction => {

				nestedTransaction.changed.forEach((changedKeys, type) => {

					// merge nested changes into native transaction
					changedKeys.forEach(changedKey => {
						map.setIfUndefined(nativeTransaction.changed, type, set.create).add(changedKey)
					})
					
					// populate the changedParentTypes map for this nested transaction
					while (true) {
						map.setIfUndefined(nestedTransaction.changedParentTypes, type, () => []);
						if (type._item === null) {
							break
						}
						type = (type._item.parent);
					}

				});

			});

			// merge all delete sets into the native transaction's deleteSet
			nativeTransaction.deleteSet = mergeDeleteSets([nativeTransaction.deleteSet, ...nestedTransactions.map(tr => tr.deleteSet)]);

			// Manually inject nested transactions into the undo manager
			nestedTransactions.forEach(nestedTransaction => {

				// make sure the transaction's origin is tracked
				globalUndoManager.updateTrackedOrigins(nestedTransaction)

				// inject sub-transaction into the undoManager's transaction handler
				globalUndoManager.yUndoManager?.afterTransactionHandler(nestedTransaction);

			});

			//console.log('done', nativeTransaction, nestedTransactions)

		}

	}, origin);

}


export class UndoManager extends Observable {

	constructor () {
		
		super()

		this.paused = false;

		this.on('yjs-before-stackitem-applied', stackItem => {

			const oldLocation = stackItem.meta.get('location');
			const oldViewport = stackItem.meta.get('viewport');

			if(oldViewport && oldViewport !== store.getState().adminState.viewport) {

				store.dispatch(actions.updateAdminState({
					viewport: oldViewport
				}));

			}

			if(oldLocation && oldLocation.pathname !== history.location.pathname) {

				history.push(oldLocation)

				// but do not apply the change yet. We want to have the tab in view before the change can 
				// be applied

				// Disabled this for now as it's adding double undo confusion. An example is 
				// when you create a new page it takes 2 undo calls to remove it again
				// stackItem.preventOnce();
			}

		})

	}

	undo() {

		if(this.paused || !this.yUndoManager.canUndo()) {
			// figure out if we want to authright ignore or force a resume here
			return;
		}

		const stackItem = this.yUndoManager.undoStack[this.yUndoManager.undoStack.length - 1];

		this.emit('yjs-before-stackitem-applied', [stackItem, 'undo']);

		if(stackItem._preventOnce && !stackItem._isOncePrevented) {
			stackItem._isOncePrevented = true;
			return;
		}

		stackItemBeingPopped = stackItem;

		this.yUndoManager.undo();
		this.emit('yjs-stackitem-applied', [stackItem, 'undo']);

		stackItemBeingPopped = undefined;


	}

	redo() {

		if(this.paused || !this.yUndoManager.canRedo()) {
			// figure out if we want to outright ignore or force a resume here
			return;
		}

		const stackItem = this.yUndoManager.redoStack[this.yUndoManager.redoStack.length - 1];
		
		this.emit('yjs-before-stackitem-applied', [stackItem, 'redo']);

		if(stackItem._preventOnce && !stackItem._isOncePrevented) {
			stackItem._isOncePrevented = true;
			return;
		}

		stackItemBeingPopped = stackItem;

		this.yUndoManager.redo();
		this.emit('yjs-stackitem-applied', [stackItem, 'redo']);

		stackItemBeingPopped = undefined;

	}

	finalizeCurrentStackItem() {
		this.yUndoManager.stopCapturing();
	}

	pause(options = {}) {

		pauseCallCount = Math.max(++pauseCallCount, 0);

		if(this.paused === true) {
			return;
		}

		this.paused = true;

		if(options.merge !== true) {
			// make sure changes during this pause
			// do not get merged with previous changes
			this.yUndoManager.stopCapturing();
		}

		this.yUndoManager.captureTimeout = Number.MAX_VALUE;

	}

	resume(options = {}) {

		pauseCallCount = Math.max(--pauseCallCount, 0);

		if(pauseCallCount > 0 || this.paused === false) {
			return;
		}

		this.paused = false;

		this.yUndoManager.captureTimeout = yjsSnapshotTimeout;

		if(options.merge !== true) {
			// make sure changes during this pause
			// do not get merged with future changes
			this.yUndoManager.stopCapturing();
		}

	}

	clear = () => {

		this.yUndoManager.stopCapturing();
		this.yUndoManager.clear();

	}

	updateTrackedOrigins = transaction => {

		if(
			// ignore external changes
			transaction.origin === wsProvider
			|| transaction.origin === YTransactionTypes.NotUndoable
			|| transaction.origin === YTransactionTypes.NotUndoableNotPublishable
		){
			// don't track any of these origins
			return;
		}

		if(this.yUndoManager && !this.yUndoManager.trackedOrigins.has(transaction.origin)) {
			this.yUndoManager.trackedOrigins.add(transaction.origin);
		}

	}

	YJSUndoController = (yType) => {

		if(!this.yUndoManager) {

			// dynamically add transaction origins. We generally want to track 
			// all of them and exclude only a particular group of origins
			yType.doc.on('afterTransaction', this.updateTrackedOrigins);
			
			// initialize with the first type we recieve
			this.yUndoManager = new Y.UndoManager(yType, {
				captureTimeout: yjsSnapshotTimeout,
				ignoreRemoteMapChanges: true
			});

			// kill native undo manager transaction listener, we
			// implement a custom version ourselves.
			this.yUndoManager.doc.off('afterTransaction', this.yUndoManager.afterTransactionHandler);

			this.yUndoManager.doc.on('afterTransaction', (transaction) => {
				if(transaction._preventNativeUndoHandler !== true) {
					this.yUndoManager.afterTransactionHandler(transaction);
				}
			});


			let currentStackItem;

			this.yUndoManager.on('stack-cleared', (event) => {
				currentStackItem = undefined;
			})

			this.yUndoManager.on('stack-item-added', (event) => {

				event.stackItem.preventOnce = function(){
					this._preventOnce = true;
					this._isOncePrevented = false;
				}.bind(event.stackItem);

				if(stackItemBeingPopped) {
					event.stackItem.meta = stackItemBeingPopped.meta;
				}

				if(currentStackItem && currentStackItem !== event.stackItem) {
					this.emit('yjs-stackitem-finalized', [currentStackItem]);
				}

				this.emit('yjs-stackitem-start', [event.stackItem]);

				event.stackItem.meta.set('location', history.location);
				event.stackItem.meta.set('viewport', store.getState().adminState.viewport);

				currentStackItem = event.stackItem;

			});

		} else {
			// add type to undo manager scope
			this.yUndoManager.addToScope(yType);
		}

	}

}

export const globalUndoManager = new UndoManager();
import convertStateToSharedType from './utils/convertStateToSharedType'
import { CRDTState, PublishState, YTransactionTypes } from '../../../globals';
import { store } from '../../../index'
import * as Sentry from "@sentry/browser";

import _ from "lodash";

// list of changes that need to be applied to the redux store
const pendingChanges = [];

// Map of shared reducers
export const sharedReducerMap = new Map();

const flushPendingChanges = _.throttle(()=> {
	if(pendingChanges.length > 0) {

		// Dispatch changes to redux
		store.dispatch({
			type: '@@y-redux/CRDT_CHANGED',
			payload: pendingChanges
		});

		// Reset the pending changes
		pendingChanges.length = 0;
	}
}, 1);

ydoc.getMap('draft-store').observeDeep((events, transaction) => {

	if(
		// if there's no socket provider, or it's not synced yet
		typeof window !== 'undefined'
		&& (
			!window.wsProvider 
			|| !window.wsProvider.synced
		)
		// only run this when not already synced before
		&& window.wsProvider?.firstSyncOccurred !== true
		// ignore initial sync message from wsprovider
		&& transaction?.origin !== window.wsProvider
	) {

		Sentry.captureException(new Error('Detected premature CRDT write'), {
			extra: {
				events,
				transaction
			}
		});

	}

	// get a list of all reducers and a matching list of paths to the reducers 
	// for fast lookup tables
	const paths = [];
	const sharedReducers = [];

	// populate lookups
	for(const [path, sharedReducer] of sharedReducerMap) {
		paths.push(path);
		sharedReducers.push(sharedReducer.type);
	}

	// match events to reducers
	events.forEach(event => {

		let changedType = event.target;

		while(changedType) {
			
			if(sharedReducers.includes(changedType)) {

				const reducerPath = paths[sharedReducers.indexOf(changedType)];

				pendingChanges.push({
					sharedReducerType: changedType,
					relativePath: event.path.join('.').substring(reducerPath.length + 1),
					event
				});

				break;
			}

			changedType = changedType.parent;
		}

	});

	flushPendingChanges();

});

export const getCRDTItem = (options = {}) => {

	// YJS map keys can only be strings. Coerce to string if not
	if(options.hasOwnProperty('item') && typeof options.item !== 'string') {
		options.item = String(options.item);
	}
	const {reducer: reducerPath, item: itemPath, readOnly = false, publishable = true} = options;
	const { type: reducerType, ignoredPaths } = sharedReducerMap.get(reducerPath);

	if(!reducerType) {
		throw `The reducer '${reducerPath}' has not been made shareable. Please wrap the reducer in with the 'sharedReducer' higher-order reducer exported from '@@multi-user/redux'`
	}

	// This indicates if something has ever been modified in the CRDT before or not. Used to run
	// initialization routines.
	let isNew = false;

	// if we're not looking for anything specific inside of the reducer
	// just return the whole thing.
	if(options.hasOwnProperty('item') === false) {

		const existingReducerState = _.get(store.getState(), reducerPath);

		// root reducer is an empty object but we have data in redux meaning it's a new uninitialized shared reducer. Fill it
		if(existingReducerState && typeof existingReducerState === 'object' && Object.keys(existingReducerState).length > 0 && reducerType.size === 0) {

			isNew = true;

			// convert the redux state into a shared YType and move it into the CRDT.
			if(!readOnly) {
				ydoc.transact(() => {

					convertStateToSharedType(existingReducerState, reducerType, (path, value) => {
						// filter out ignored paths
						if(ignoredPaths.includes(path.join('.'))) {
							return false;
						}
					}, publishable ? YTransactionTypes.NotUndoable : YTransactionTypes.NotUndoableNotPublishable);

					// if the newly created state has a crdt_state, set it to draft
					if(reducerType.has('crdt_state')) {
						reducerType.set('crdt_state', CRDTState.Draft);
					}

				}, publishable ? YTransactionTypes.NotUndoable : YTransactionTypes.NotUndoableNotPublishable);

			}
			
		}

		return {
			CRDTItem: reducerType,
			isNew
		};
	}

	if(!reducerType.has(itemPath)) {

		isNew = true;

		if(!readOnly) {
	
			// console.log('Item does not exist in CRDT. Creating a new CRDT item now');

			if(!store) {
				console.error('Unable to find Redux store.');
				return;
			}

			const existingReduxState = _.get(store.getState(), reducerPath);

			if(!existingReduxState) {
				throw `'${reducerPath}' does not exist in the Redux store. Unable to create the CRDT item without initial data`;
			}

			// grab the redux content for this path
			const targetStateItem = existingReduxState[itemPath];

			if(!targetStateItem) {
				throw `'${reducerPath}.${itemPath}' does not exist in the Redux store. Unable to create the CRDT item without initial data`;
			}

			// convert the redux state into a shared YType and move it into the CRDT.
			ydoc.transact(() => {
				
				const sharedState = convertStateToSharedType(targetStateItem, undefined, (path, value) => {

					// include the path from the root of the reducer up till this item
					path.unshift(itemPath);

					// filter out ignored paths
					if(ignoredPaths.includes(path.join('.'))) {
						return false;
					}

				}, publishable ? YTransactionTypes.NotUndoable : YTransactionTypes.NotUndoableNotPublishable);

				reducerType.set(itemPath, sharedState);

				// if the copied state has a crdt_state, set it to draft
				if(targetStateItem?.hasOwnProperty('crdt_state')) {
					sharedState.set('crdt_state', CRDTState.Draft);
				}

				// if the root reducer has a crdt_state, set it as well
				if(existingReduxState?.hasOwnProperty('crdt_state')) {
					reducerType.set('crdt_state', CRDTState.Draft);
				}

			}, publishable ? YTransactionTypes.NotUndoable : YTransactionTypes.NotUndoableNotPublishable);

		}

	}

	return {
		CRDTItem: reducerType.get(itemPath),
		isNew
	};

}

window.getCRDTItem = getCRDTItem

export const hasCRDTItem = ({reducer: reducerPath, item: itemPath}) => {

	const reducerType = sharedReducerMap.get(reducerPath)?.type;

	// YJS map keys can only be strings. Coerce itemPath to string if not
	if(itemPath !== undefined && typeof itemPath !== 'string') {
		itemPath = String(itemPath);
	}

	if(!reducerType) {
		throw `The reducer '${reducerPath}' has not been made shareable. Please wrap the reducer in with the 'sharedReducer' higher-order reducer exported from '@@multi-user/redux'`
	}

	if(itemPath === undefined) {
		// we're not looking for anything inside the reducer. Check to see if there's any data in the reducer's root
		return reducerType.size !== 0;
	}

	return reducerType.has(itemPath);

}

// Export the `sharedReducer` reducer enhancer.
export { sharedReducer } from './reducer';

// export utils
export {default as applyChangesToYType} from './utils/applyChangesToYType';
export {default as convertStateToSharedType} from './utils/convertStateToSharedType';

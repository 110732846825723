import React from 'react';
import { createRoot } from 'react-dom/client';
import loadable from '@loadable/component'
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router } from "react-router";
import { matchPath } from 'react-router';
import AdminRouter from "./router";
import {colors} from "@cargo/common/users";
import reducers from "./reducers";
import * as adminActionsModule from "./actions";
import selectors from "./selectors";
import _ from 'lodash';

import { FRONTEND_DATA, YTransactionTypes } from "./globals";
import axios from 'axios';
import "./middleware/axiosDefaults";
import "./middleware/axiosAuth";
import globalDragEventController from "./components/drag-event-controller";
import cargoFetch from '@cargo/fetch';
import { Message, MessageContext } from "@cargo/ui-kit/message/message-controller";

import { setupY } from "./lib/multi-user";
import { getCRDTItem, convertStateToSharedType, applyChangesToYType } from "./lib/multi-user/redux";
import { setupSharedDomListener } from "./lib/shared-dom";

import { setMorphdomDoc } from "./lib/morphdom/util";
import { initCSSSubscriptions } from "./lib/shared-css/global-css-subscriptions";
import { observePageContentForMediaChanges } from "./lib/media/mediaInUse";

import { deviceType, isLocalEnv, isServer, isProduction, isMac, isIOS } from "@cargo/common/helpers";
import { API_ORIGIN, INTERCOM_APP_ID } from '@cargo/common'
import { initDarkMode } from './darkmode';
import { LoggedOutDarkModeHandler } from './loggedout-darkmode-handler';

import * as Sentry from "@sentry/browser";
import { IntercomProvider } from 'react-use-intercom';

import "./css/admin.scss";

window.__c3_admin__ = true;

let globalStore;
let globalHistory;

if (!isServer && !isLocalEnv) {

	Sentry.init({
		environment: CARGO_ENV,
		release: BUILD_VERSION,
		beforeSend(event) {
			return event;
		},
		ignoreErrors: [
			// do not report invalid file upload errors
			'did not upload because it is larger than',
			'There is a 3MB limit on the social media image preview image',
			'Your upload is not a supported file type',
			'Unsupported file type',
			'The mime type field is required',
			// or brain tree validation errors
			'Some payment input fields are invalid',
			'All fields are empty',
			'Unable to save card',
			'No valid Credit Card form found',
			// or generic DOM errors
			'ResizeObserver loop completed with undelivered notifications',
			// webkit plugins
			'webkit-masked-url://hidden',
			// Unauthorized requests,
			'Request failed with status code 403',
			// CRDT quick connect rejections,
			'Non-Error promise rejection captured with value: Unauthorized',
			// Cannot redefine property: googletag
			'googletag'
		],
		denyUrls: [
			// Safari extensions
			'webkit-masked-url',
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i
		],
		dsn: "https://c62f560e3c834680a645d941977f1a9e@o4504992622247936.ingest.sentry.io/4504992645578752",
		integrations: integrations => {
			return [
				...integrations,
				new Sentry.BrowserTracing()
			]
		},
		tracesSampleRate: 0,
		tunnel: `${API_ORIGIN.replace('/v1', '')}/sentry/tunnel`,
	});

}

if(window.location.host.includes('local.dev.cargo.site')) {
	// Collapse the massive error "Warning: validateDOMNesting(...): <hr> cannot appear as a child of <select>."
	const ogErrorLog = window.console.error;
	window.console.error = function(msg){
		const isAnnoyingAssReactMessage = typeof msg === "string" && msg.includes('validateDOMNesting');
		isAnnoyingAssReactMessage && console.groupCollapsed('Collapsed error');
		ogErrorLog.apply(this, arguments);
		isAnnoyingAssReactMessage && console.groupEnd('Collapsed error');
	}
}

(function(CargoEditor, undefined){

	/**
	 *	Extend Node for the Inspector
	 */

	var addToProto = function(target, key, val){
		if(target.prototype.hasOwnProperty(name) === false) {
			target.prototype[key] = val;
		} else {
			return false;
		}
	}

	addToProto(Node, 'saveable', false);

	addToProto(Node, 'setSaveable', function(val){
		this.saveable = val;

		return this;
	});

	addToProto(Node, 'isSaveable', function(){
		return this.saveable;
	});

	
	Node.prototype.persistCloneNode = function(){

		var getAllDescendants = function(original, clone, callback){
			for (var i = 0; i < original.childNodes.length; i++) {
				callback(original.childNodes[i], clone.childNodes[i]);

				if(original.childNodes[i].childNodes.length > 0){
					getAllDescendants(original.childNodes[i], clone.childNodes[i], callback);
				}
			}
		}

		var result = Node.prototype.cloneNode.apply(this, arguments);
		
		if(this.isSaveable() === true) {
			result.setSaveable(true);
		}

		// also check child nodes
		if(this.childNodes.length > 0){
			getAllDescendants(this, result, function(originalChild, clonedChild){
				if(originalChild.isSaveable() === true) {
					clonedChild.setSaveable(true);
				}
			})
		}

		return result;
		
	}

})(window.CargoEditor = window.CargoEditor || {});

const initClientFrame = async () => {

	// start the loading animation
	renderLoadingAnimation();

	FRONTEND_DATA.contentWindow = clientFrame.contentWindow;

	let quickCRDTConnectionPromise = setupY({
		quickConnect: true,
		onOutdatedBuild: renderUnrecoverableErrorMessage
	});

	let userdataPrefetchPromise = undefined;

	try {

		// grab all required data to connect from localstorage
		const { id: userid, access_token } = JSON.parse(localStorage.getItem('c3-auth'));

		if(userid && access_token) {
			userdataPrefetchPromise = axios.get(`${API_ORIGIN}/users/${userid}`, {
				headers: {
					'Authorization': `Bearer ${access_token}`
				}
			});
		}

	} catch(e) {}

	const frontend = await getClientAPI();

	FRONTEND_DATA.history = frontend.history;
	FRONTEND_DATA.globals = frontend.globals;
	FRONTEND_DATA.galleryData = frontend.galleryData;
	FRONTEND_DATA.editorOverlayAPI = frontend.editorOverlayAPI;
	FRONTEND_DATA.globals.ADMIN_FRAME.getCRDTItem = getCRDTItem;
	FRONTEND_DATA.globals.ADMIN_FRAME.convertStateToSharedType = convertStateToSharedType;
	FRONTEND_DATA.globals.ADMIN_FRAME.applyChangesToYType = applyChangesToYType;

	// allow binding of mouse events to the window for things like resizing images out of bounds
	FRONTEND_DATA.globals.ADMIN_FRAME.adminWindow = window;		
	FRONTEND_DATA.globals.ADMIN_FRAME.globalDragEventController = globalDragEventController;

	// for debugging purposes
	window.store = frontend.store;
	globalStore = frontend.store;

	// merge the frontend actions into the admin actions for easy
	// access across apps
	_.merge(adminActionsModule, frontend.actions);

	// merge admin action types into the frontend so that they
	// can be used in frontend reducers (like UPDATE_SITE_DESIGN)
	_.merge(frontend.actions.actionTypes, adminActionsModule.actionTypes);

	let wsProvider;
	let isConnected = false;

	while(!isConnected) {

		try {

			wsProvider = await (quickCRDTConnectionPromise || setupY({
				onOutdatedBuild: renderUnrecoverableErrorMessage
			}));

			// Do not use the preconnect promise twice
			quickCRDTConnectionPromise = undefined;

			// if we made it here we've succesfully connected to the CRDT server,
			// break the connection loop and resume init.
			isConnected = true;

		} catch(reason) {

			// Do not use the preconnect promise twice
			quickCRDTConnectionPromise = undefined;

			// Discard potentially stale user data request
			userdataPrefetchPromise = undefined;

			// when the admin version is out of date we do not attempt to init further.
			if(reason === "Upgrade Required") {
				return;
			}

			store.dispatch({
				type: frontend.actions.actionTypes.AUTHENTICATE_USER_REJECTED,
				payload: {}
			});

			await new Promise(resolve => {

				renderLoginApp();

				// await successful login
				const unsubscribe = store.subscribe(() => {

					const state = store.getState();

					if(state.auth.authenticated) {

						// wipe the login screen and start the loading animation again.
						renderLoadingAnimation();

						// stop listening to store updates
						unsubscribe();

						// fetch site package
						store.dispatch(frontend.actions.actions.fetchSitePackage()).finally(() => {
							// resolve the promise and keep initing
							resolve();
						});

					}

				})

			})

		}

	}

	const state = frontend.store.getState();

	cargoFetch.setAuthorization(state.auth.data.access_token);

	// enrich Sentry events
	Sentry.setUser({ 
		id: state.auth.data.id 
	});
	Sentry.setTag("site_id", state.site.id);
	Sentry.setTag("url", state.site.site_url);

	// confirm the site id of the site model is the same 
	// as the site the CRDT has connected to
	if(String(state.site.id) !== wsProvider.configuration.name) {

		console.log('Site connected using an incorrect site ID. Removing the stale c3-site entry and reloading...');

		try {
			localStorage.removeItem('c3-site');
		} catch(e) { console.error(e); }
		
		window.location.reload();
		return;
	}

	// setup user awareness
	const existingAwarenessState = _.find([...wsProvider.awareness.getStates().values()], (awarenessState) => {
		return state.auth.data.id && awarenessState.user?.cargoId === state.auth.data.id
	});
	// remove already used colors from the list
	let awarenessValues = wsProvider.awareness.getStates().values();
	let usedColors = Array.from(awarenessValues).map(state => {
			if (state.user?.color) {
				return state.user?.color
			}
	});
	let allColors = _.clone(colors);
	const availableColors = _.difference(allColors, usedColors);

	// if user already has a color, just use that
	let color = existingAwarenessState?.user?.color;

	// otherwise, get an available color or fall back to a random color from the list in case we ran out
	if(!color) {
		color = availableColors.length > 0 ? 
			availableColors[Math.floor(Math.random()*availableColors.length)] :
			colors[Math.floor(Math.random()*colors.length)]
	}

	wsProvider.setAwarenessField('user', {
		cargoId: parseInt(state.auth.data.id),
		color: color,
		yjsId: ydoc.clientID,
	});

	// create connected router history
	const history = createBrowserHistory({
		basename: '/edit',
	});

	globalHistory = history;

	// helper for the client frame
	window.navigateAdmin = (url) => {
		history.push(url)
	}

	// we only use one redux store. Grab the
	// frontend reducers and combine them with
	// our admin reducers. Then replace the root
	// reducer on the now shared store.
	ydoc.transact(() => {

		frontend.store.replaceReducer(
			reducers(history, frontend.reducers, frontend.combineReducers)
		);

		// ensure that the adminState CRDT entry always has it's crdt' field initialized.
		// The server requires this for writing pub/sub messages
		getCRDTItem({
			reducer: 'adminState',
			item: 'crdt'
		});

	}, YTransactionTypes.NotUndoableNotPublishable);

	// merge the frontend selectors into the admin selectors for easy
	// access across apps
	_.merge(selectors, frontend.selectors);

	// make sure morphdom is using the iframe document, rather than the admin one
	setMorphdomDoc(FRONTEND_DATA.contentWindow.document);

	setupSharedDomListener();

	initCSSSubscriptions(frontend.store.getState());

	// watch for changes to in-use media
	observePageContentForMediaChanges();

	// set user data if we have prefetched
	if(userdataPrefetchPromise) {
		store.dispatch(adminActionsModule.actions.fetchUser({
			request: userdataPrefetchPromise
		}));
	}

	// init dark mode
	initDarkMode(store);

	// tell the frontend we're in admin mode
	store.dispatch(adminActionsModule.actions.updateFrontendState({
		adminMode: true
	}));

	// set initial synced status
	store.dispatch(adminActionsModule.actions.updateAdminState({
		CRDTSynced: wsProvider.synced
	}))

	// now that we hooked everything up, init the admin.
	renderAdminApp(frontend.store, history);

}

const getClientAPI = () => {

	let clientAPI = null;

	return new Promise(resolve => {

		const isAvailable = () => {

			if(
				clientFrame 
				&& clientFrame.contentWindow
				&& typeof clientFrame.contentWindow.getClientAPI === "function"
			) {

				clientAPI = clientFrame.contentWindow.getClientAPI();

				const frontendState = clientAPI.store.getState().frontendState;

				setupStoreListener(clientAPI.store);

				// now make sure we have CSS in the frontend & site model data
				if(
					frontendState?.hasSiteCSS !== true
					|| frontendState?.hasSiteModel !== true
				) {
					setTimeout(isAvailable, 30);
					return;
				}

				const state = clientAPI.store.getState();

				// set site title
				document.title = state.site.website_title;

				// set favicon
				const favionTag = document.querySelector("link[rel~='icon']");

				if(favionTag) {
					favionTag.href = state.site.favicon_url;
				}

				resolve(clientAPI);

			} else {

				setTimeout(isAvailable, 30);

			}

		}

		isAvailable();

	});

}

const setOSClass = () => {
	if (!isMac() && !isIOS()) {
		document.body.classList.add('f-f');
	}
}


let currentViewport;
const onViewportChange = newViewport => {

	if(newViewport !== currentViewport) {

		// wait till frontend started working on a new page load
		setTimeout(() => {

			if(FRONTEND_DATA.contentWindow.__deferred_page_load_promise__) {

				// if indeed working on loading a new page, wait till it's ready
				FRONTEND_DATA.contentWindow.__deferred_page_load_promise__.then(() => {
					deviceViewport.className = newViewport;
					clientFrame.style.width = '';
				})

			} else {

				// instantly switch
				deviceViewport.className = newViewport;

				if( newViewport !== 'mobile' ){
					clientFrame.style.width = '';
				} else {
					window.dispatchEvent(new Event('resize'));
				}
				

			}

		})

		// set the new viewport in store
		currentViewport = newViewport;
	}

}

let currentAuthState;
const onAuthChange = newAuthState => {

	if(currentAuthState !== newAuthState) {
		
		// we have to run this as soon as possible, otherwise a visible jump will occur.
		if(newAuthState === false) {
			document.body.classList.add('not-authorized')
		} else {
			document.body.classList.remove('not-authorized')
		}

	}

	currentAuthState = newAuthState;

}

const setupStoreListener = _.once(store => {

	store.subscribe(() => {

		const state = store.getState();
		
		onViewportChange(state.adminState?.viewport);
		onAuthChange(state.auth?.authenticated);

	});

	// immediately run with current data
	const state = store.getState();
	
	onViewportChange(state.adminState?.viewport);
	onAuthChange(state.auth?.authenticated);

});

const initDeviceManager = () => {

	const maintainMobileRatio = _.throttle(() => {

		if( store.getState().adminState.viewport == 'mobile' ){
			let ratio        = store.getState().adminState.mobileRatio;
			let maxWidth     = clientFrame.getBoundingClientRect().height;
			let currRatio    = store.getState().adminState.mobileRatio;


			maxWidth = clientFrame.getBoundingClientRect().height;
			let width = maxWidth * currRatio;
			clientFrame.style.width = Math.round(width) + 'px';
		}

	}, 15)

	store.subscribe(() => {
		// If they're not equal change the viewport's width.
		if( store.getState().adminState.viewport == 'mobile' ){
			maintainMobileRatio()
		}
	})

	const resizeListener = _.throttle(maintainMobileRatio, 15);
	window.addEventListener("resize", resizeListener);

}

const appRoot = createRoot(
	document.querySelector('#admin-app')
)

let loadingAnimationTimeout;

const renderLoadingAnimation = () => {

	// clear previous timeout if existing
	clearTimeout(loadingAnimationTimeout);

	appRoot.render(null);

	loadingAnimationTimeout = setTimeout(() => {

		appRoot.render(
			<Message>
				<MessageContext.Consumer>
					{(Message) => {

						setTimeout(() => {
							Message.showMessage({
								messageText: ' ',
								type: 'loading',
								preventClickout: true
							});
						});

					}}
				</MessageContext.Consumer>
			</Message>
		)

	}, 1000);

}

const renderUnrecoverableErrorMessage = () => {

	// stop loader from rendering
	clearTimeout(loadingAnimationTimeout);

	appRoot.render(
		<Message>
			<MessageContext.Consumer>
				{(Message) => {

					setTimeout(() => {
						Message.showMessage({
							messageText: 'Error loading Cargo<br />Please <a href="mailto:support@cargo.site" class="email-link">email us</a> for more info',
							pointerEvents: true,
							preventDefaultClickHandlers: true
						});
					});

				}}
			</MessageContext.Consumer>
		</Message>
	)

}

const renderLoginApp = () => {

	// stop loader from rendering
	clearTimeout(loadingAnimationTimeout);

	const Login = loadable(async () => import('@cargo/common/login').then(_module => _module.Login));

	appRoot.render(
		<Provider store={store}>
			<LoggedOutDarkModeHandler />
			<Login 
				canCreateNewAccount={false}
				getAuthTokenAction={adminActionsModule.actions.getAuthToken} 
			/>
		</Provider>
	);

};

const renderAdminApp = (store, history) => {

	// stop loader from rendering
	clearTimeout(loadingAnimationTimeout);

	// only load App component on demand, not before we're ready.
	const App = loadable(() => import('./components/app'));

	appRoot.render(
		<Provider store={store}>
			<IntercomProvider
				appId={INTERCOM_APP_ID}
				autoBoot={false}
				tunnelEnabled={INTERCOM_TUNNEL_ENABLED}
				tunnelPath={'/edit/intercom.tunnel/'}
			>
				<Router basename="edit" history={history}>
					<AdminRouter>
						<App />
					</AdminRouter>
				</Router>
			</IntercomProvider>
		</Provider>
	);

	store.dispatch({
		type: adminActionsModule.actionTypes.UPDATE_ADMIN_STATE, 
		payload: {
			initialized: true
		}
	})

	initDeviceManager();
	setOSClass();

}

const clientFrame = document.getElementById('client-frame');
const deviceViewport = document.getElementById('device-viewport');
const isTouchscreen = deviceType() === 'touch';

clientFrame.addEventListener('load', () => {

	// content frame is being navigated. This breaks all references editor has to frontend
	// and forces us to completely prevent the editor from being used
	try {
		clientFrame.contentWindow.addEventListener('unload', () => {

			// kill the frame
			clientFrame.remove();

			// render a message letting the user know something went wrong
			renderUnrecoverableErrorMessage();

		});
	} catch(e) {
		console.error(e);
	}

}, {once : true});

// Prevent default image drop behavior
window.addEventListener("dragenter", e => e.preventDefault(), false);
window.addEventListener("dragleave", e => e.preventDefault(), false);
window.addEventListener("dragover", e => e.preventDefault(), false);
window.addEventListener("drop", e => e.preventDefault(), false);

if( isTouchscreen ){

	const mobileEditStyle = { display:'block',fontSize:'24px',padding:'3rem',lineHeight:'1.3em',minHeight:'4rem',flex:'1',flexWrap:'wrap',width:'100%',border:'0',maring:'0',letterSpacing:'0.03em',fontWeight:400,fontStyle:'normal',textRendering:'optimizeLegibility',fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif'}
	appRoot.render(
		<div style={mobileEditStyle}>Please use a desktop browser to edit your site.</div>
	);

	// log to Sentry
	/*Sentry.captureMessage('Prevented non-desktop browser from editing', {
		extra: {
			isHover         : window.matchMedia(`(hover:hover)`).matches,
			isHoverNone     : window.matchMedia(`(hover:none)`).matches,
			isPointerFine   : window.matchMedia(`(any-pointer:fine)`).matches,
			isPointerCoarse : window.matchMedia(`(pointer:coarse)`).matches,
			isPointerNone   : window.matchMedia(`(pointer:none)`).matches
		}
	});*/

} else {

	initClientFrame();

}


export { globalStore as store };
export { globalHistory as history };
